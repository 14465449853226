import FAQ                from '@/domains/marketing/components/FAQ';
import Hero               from '@/domains/marketing/components/Hero';
import Pricing            from '@/domains/marketing/components/Pricing';
import WhoWeServe         from '@/domains/marketing/components/WhoWeServe';
import PrimaryLayout      from '@/domains/marketing/layouts/PrimaryLayout';
import GradientTransition from '@/domains/shared/components/GradientTransition';

export default function Home() {
	return (
		<PrimaryLayout pageTitle="Atlas Software | Australian software development agency for Laravel + Vue + React Native | Web and mobile app developers"
		               description="Australia's premier Laravel development agency for web and mobile applications"
		>
			<Hero />

			{/*<WhoWeServe transitions={[*/}
			{/*	// <GradientTransition key={0}*/}
			{/*	//                     direction="down"*/}
			{/*	//                     from="from-teal-900/10"*/}
			{/*	// />,*/}
			{/*	// <GradientTransition key={1}*/}
			{/*	//                     direction="up"*/}
			{/*	//                     from="from-teal-800"*/}
			{/*	// />,*/}
			{/*]}*/}
			{/*/>*/}

			{/*<Testimonials />*/}

			{/*<Flexible />*/}

			{/*<Pricing transitions={[*/}
			{/*	// <GradientTransition key={0}*/}
			{/*	//                     direction="down"*/}
			{/*	//                     from="from-teal-600"*/}
			{/*	// />,*/}
			{/*	<GradientTransition key={1}*/}
			{/*	                    direction="up"*/}
			{/*	                    from="from-teal-600"*/}
			{/*	/>,*/}
			{/*]}*/}
			{/*/>*/}

			{/*<FAQ />*/}
		</PrimaryLayout>);
}
