import {Container} from '@/domains/marketing/components/Container';
import {
	BoltIcon,
	BuildingOffice2Icon,
	LightBulbIcon,
}                  from '@heroicons/react/20/solid';
import classNames  from 'classnames';

const personas = [
	{
		name:        'MVPs',
		className:   'left-[15%] top-4',
		description: 'We\'ll sit down with you, take your initial scope, and turn it into a product you can actually sell within weeks.',
		icon:        LightBulbIcon,
	},
	{
		name:        'Startups',
		className:   'left-[40%] top-2',
		description: 'We can build your product, while you retain equity and reduce the risk of hiring permanent staff.',
		icon:        BoltIcon,
	},
	{
		name:        'New products for existing businesses',
		className:   'left-[75%] -top-2',
		description: 'We can work with your team, or work independently, to add new features or entirely new products to your lineup.',
		icon:        BuildingOffice2Icon,
	},
];

export default function WhoWeServe({
	                                   transitions = [],
                                   }) {
	return (<div className="relative bg-teal-600 py-24 sm:py-32">
		{transitions}

		<Container className="space-y-24">
			<div>
				<div className="mx-auto max-w-4xl text-center">
					<h2 className="text-base font-semibold leading-7 text-teal-50">Customers</h2>
					<p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
						Who do we serve best?
					</p>
				</div>

				<p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-white/80">
					While we can develop web and mobile apps for anyone, our customers typically look like this.
				</p>
			</div>

			<div className="md:hidden">
				<dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-teal-50 lg:max-w-none">
					{personas.map((persona) => (<div key={persona.name}
					                                 className="relative pl-9"
					>
						<dt className="block font-semibold text-teal-50">
							<persona.icon className="absolute left-1 top-1 h-5 w-5 text-teal-100"
							              aria-hidden="true"
							/>

							{persona.name}
						</dt>
						{' '}

						<dd className="inline">{persona.description}</dd>
					</div>))}
				</dl>
			</div>

			<div className="hidden md:block relative h-[300px]">
				<div className="h-3 -skew-y-[0.75deg] bg-orange-400 w-full rounded-full absolute" />

				{personas.map((persona) => {
					return (<div key={persona.name}
					             className={classNames([
						             'group w-auto md:max-w-[11rem] lg:max-w-[14rem] xl:max-w-[18rem] rounded absolute -translate-y-8 -translate-x-1/2 select-none flex flex-col items-center',
						             persona.className,
					             ])}
					>
						<p className="font-bold text-orange-600 text-lg text-center bg-white/90 backdrop-blur group-hover:bg-white px-4 py-2 rounded-t-lg max-w-[75%]">
							{persona.name}
						</p>

						<p className="bg-white/90 backdrop-blur group-hover:bg-white p-4 rounded-lg text-center">
							{persona.description}
						</p>
					</div>);
				})}
			</div>
		</Container>
	</div>);
}
