import {Container}   from '@/domains/marketing/components/Container';
import PricingGrid   from '@/domains/marketing/components/PricingGrid';
import PricingTable  from '@/domains/marketing/components/PricingTable';
import {Button}      from '@/domains/shared/components/Button';
import {frequencies} from '@/domains/shared/pricing';
import {RadioGroup}  from '@headlessui/react';
import classNames    from 'classnames';
import {useState}    from 'react';

export default function Pricing({
	                                withPricingGrid = true,
	                                withPricingTable = false,
	                                transitions = [],
                                }) {
	const [frequency, setFrequency] = useState(0);

	return (<div className="relative bg-teal-800 py-24 sm:py-32">
		{transitions}

		<Container className="isolate">
			<div className="mx-auto max-w-4xl text-center">
				<h2 className="text-base font-semibold leading-7 text-teal-50">Pricing</h2>
				<p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
					Simple fixed pricing
				</p>
			</div>

			<p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-white/60">
				Choose the plan that best suits your budget and required velocity.
				Cancel any time, without paying employee redundancy.
			</p>

			<div className="mt-16 flex justify-center">
				<RadioGroup value={frequency}
				            onChange={setFrequency}
				            className={classNames([
					            'grid gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white',
					            frequencies.length === 2 ? 'grid-cols-2' : '',
					            frequencies.length === 3 ? 'grid-cols-3' : '',
				            ])}
				>
					<RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>

					{frequencies.map((option, optionIndex) => (<RadioGroup.Option
						key={optionIndex}
						value={optionIndex}
						className={({checked}) => classNames(checked ? 'bg-teal-500' : '', 'cursor-pointer rounded-full px-2.5 py-1')}
					>
						<span>{option.label}</span>
					</RadioGroup.Option>))}
				</RadioGroup>
			</div>

			{withPricingGrid && <PricingGrid frequency={frequency} />}

			{withPricingTable && <PricingTable frequency={frequency} />}

			<div className="mt-8 bg-white flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:flex-row lg:items-center">
				<div className="lg:min-w-0 lg:flex-1">
					<h3 className="text-lg font-semibold leading-8 tracking-tight text-teal-600">
						Looking for more?
					</h3>

					<p className="mt-1 text-base leading-7 text-gray-600">
						Contact us to discuss the needs of your project, so we can make sure we&apos;re the best
						fit.
					</p>
				</div>

				<div className="flex justify-end gap-4">
					{!withPricingTable && <Button href="/pricing"
					                              color="gray"
					                              variant="outline"
					>
						Plan details
					</Button>}

					<Button href="/contact-us"
					        color="teal"
					>
						Contact us
					</Button>
				</div>
			</div>
		</Container>
	</div>);
}
