import {Container}                   from '@/domains/marketing/components/Container';
import HeroCode                      from '@/domains/marketing/components/HeroCode';
import {Button}                      from '@/domains/shared/components/Button';
import {ArrowRightMovingOnHoverIcon} from '@/domains/shared/components/icons/ArrowRightMovingOnHoverIcon';
import classNames                    from 'classnames';
import Link                          from 'next/link';

export default function Hero({className}) {
	const code = `<?php

namespace App\\Providers;

use Atlas\\Business\\BillingModels\\Days;
use Atlas\\Business\\Services\\Development;
use Carbon\\Carbon;
use Carbon\\CarbonInterval;
use Illuminate\\Support\\ServiceProvider;

class AppServiceProvider extends ServiceProvider
{
    public function boot(): void {
        Development::developers(2)
            ->engagement(CarbonInterval::weeks(8))
            ->commencing(Carbon::parse('first Monday of next month'))
            ->kickOffCall(Carbon::MONDAY)
            ->weeklyUpdateCalls(Carbon::FRIDAY)
            ->billingModel(Days::perWeek(2))
            ->hire();
    }
}
`;

	return (<div className={classNames(className, 'bg-white')}>
		<div className="relative isolate overflow-hidden bg-gradient-to-b from-teal-100/20 pt-48 -mt-48">
			<Container className="pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:py-40">
				<div className="max-w-2xl flex justify-center items-center">
					<div>
						<div>
							<Link href="/case-studies/labourpower-portal"
							      className="inline-flex space-x-6 group"
							>
								<span className="rounded-full bg-teal-500/10 px-3 py-1 text-sm font-semibold leading-6 text-teal-600 ring-1 ring-inset ring-teal-600/20">
									Our latest case study
								</span>

								<span className="inline-flex items-center space-x-2 text-sm leading-6 font-semibold">
									<span className="bg-clip-text text-transparent bg-gradient-to-r from-teal-500 to-teal-600 group-hover:to-teal-700 transition">
										Rebuilding the Labourpower Portal
									</span>

									<ArrowRightMovingOnHoverIcon className="h-5 w-5 text-teal-600 group-hover:text-teal-700 transition" />
								</span>
							</Link>
						</div>

						<h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
							Need help getting your project off the ground?
						</h1>

						<p className="mt-6 text-lg leading-8 text-gray-600">
							We&apos;re experts in building web and mobile applications in Laravel.
							<br />
							Let our team take your project to the top.
						</p>

						<div className="mt-10 flex items-center gap-x-6">
							<Button color="teal"
							        href="/services/development"
							>
								Our services
							</Button>

							{/*<Button variant="bare"*/}
							{/*        color="white"*/}
							{/*        href="/pricing"*/}
							{/*>*/}
							{/*	Our pricing*/}
							{/*</Button>*/}
						</div>
					</div>
				</div>

				<div className="mt-20 sm:mt-24 md:mx-auto lg:mx-0 lg:mt-0">
					<div className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-teal-600/10 ring-1 ring-teal-50 md:-mr-20 lg:-mr-36"
					     aria-hidden="true"
					/>

					<div className="shadow-lg md:rounded-3xl">
						<div className="bg-teal-500 [clip-path:inset(0)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
							<div className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-teal-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
							     aria-hidden="true"
							/>

							<div className="relative px-6 pt-8 sm:pt-16 md:pl-16 md:pr-0">
								<div className="mx-auto max-w-2xl md:mx-0 md:max-w-none">
									<div className="w-screen overflow-hidden rounded-tl-xl bg-gray-900">
										<div className="flex bg-gray-800/40 ring-1 ring-white/5">
											<div className="-mb-px flex text-sm font-medium leading-6 text-gray-400">
												<button type="button"
												        className="border-b border-r border-b-white/20 border-r-white/10 bg-white/5 px-4 py-2 text-white cursor-pointer select-none"
												>
													AppServiceProvider.php
												</button>
												<button type="button"
												        className="border-r border-gray-600/10 px-4 py-2 cursor-pointer select-none"
												        onClick={() => {
													        alert('There are no Easter Eggs up here.');
												        }}
												>
													Index.vue
												</button>
											</div>
										</div>
										<div className="px-6 pb-14 pt-6">
											<HeroCode />
											{/*<pre className="text-[0.8125rem] leading-6 text-gray-300 whitespace-pre-wrap"*/}
											{/*     style={{tabSize: 2}}*/}
											{/*><code>{code}</code></pre>*/}
										</div>
									</div>
								</div>

								<div className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 md:rounded-3xl"
								     aria-hidden="true"
								/>
							</div>
						</div>
					</div>
				</div>
			</Container>

			<div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
		</div>
	</div>);
}
