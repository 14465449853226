function padSpaces(count) {
	return ' '.repeat(count);
}

export default function HeroCode() {
	return (
		<div className="relative w-full font-mono text-[0.8125rem] leading-6 text-gray-300 whitespace-pre-wrap overflow-x-scroll outline-0">
			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(255,123,114)]">{`<?php`}</span>
			</span>

			<br />

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(255,123,114)]">namespace</span>
				<span className="text-[rgb(230,237,243)]"> </span>
				<span className="text-[rgb(255,166,87)]">App\Providers</span>
				<span className="text-[rgb(230,237,243)]">;</span>
			</span>

			<br />

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(255,123,114)]">use</span>
				<span className="text-[rgb(230,237,243)]"> </span>
				<span className="text-[rgb(121,192,255)]">Atlas\Business\Enums\BillingCycles</span>
				<span className="text-[rgb(230,237,243)]">;</span>
			</span>

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(255,123,114)]">use</span>
				<span className="text-[rgb(230,237,243)]"> </span>
				<span className="text-[rgb(121,192,255)]">Atlas\Business\Services\Development</span>
				<span className="text-[rgb(230,237,243)]">;</span>
			</span>

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(255,123,114)]">use</span>
				<span className="text-[rgb(230,237,243)]"> </span>
				<span className="text-[rgb(121,192,255)]">Carbon\Carbon</span>
				<span className="text-[rgb(230,237,243)]">;</span>
			</span>

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(255,123,114)]">use</span>
				<span className="text-[rgb(230,237,243)]"> </span>
				<span className="text-[rgb(121,192,255)]">Carbon\CarbonInterval</span>
				<span className="text-[rgb(230,237,243)]">;</span>
			</span>

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(255,123,114)]">use</span>
				<span className="text-[rgb(230,237,243)]"> </span>
				<span className="text-[rgb(121,192,255)]">Illuminate\Support\ServiceProvider</span>
				<span className="text-[rgb(230,237,243)]">;</span>
			</span>

			<br />

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(255,123,114)]">class</span>
				<span className="text-[rgb(230,237,243)]"> </span>
				<span className="text-[rgb(255,166,87)]">AppServiceProvider</span>
				<span className="text-[rgb(230,237,243)]"> </span>
				<span className="text-[rgb(255,123,114)]">extends</span>
				<span className="text-[rgb(230,237,243)]"> </span>
				<span className="text-[rgb(121,192,255)]">ServiceProvider</span>
			</span>

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(230,237,243)]">{`{`}</span>
			</span>

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(230,237,243)]">{padSpaces(4)}</span>
				<span className="text-[rgb(255,123,114)]">public</span>
				<span className="text-[rgb(230,237,243)]"> </span>
				<span className="text-[rgb(255,123,114)]">function</span>
				<span className="text-[rgb(230,237,243)]"> </span>
				<span className="text-[rgb(210,168,255)]">boot</span>
				<span className="text-[rgb(230,237,243)]">()</span>
				<span className="text-[rgb(255,123,114)]">:</span>
				<span className="text-[rgb(230,237,243)]"> </span>
				<span className="text-[rgb(255,123,114)]">void</span>
				<span className="text-[rgb(230,237,243)]"> {`{`}</span>
			</span>

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(230,237,243)]">{padSpaces(8)}</span>
				<span className="text-[rgb(121,192,255)]">Development</span>
				<span className="text-[rgb(255,123,114)]">::</span>
				<span className="text-[rgb(210,168,255)]">teamSize</span>
				<span className="text-[rgb(230,237,243)]">(</span>
				<span className="text-[rgb(121,192,255)]">2</span>
				<span className="text-[rgb(230,237,243)]">)</span>
			</span>

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(230,237,243)]">{padSpaces(19)}</span>
				<span className="text-[rgb(255,123,114)]">-&gt;</span>
				<span className="text-[rgb(210,168,255)]">daysPerWeek</span>
				<span className="text-[rgb(230,237,243)]">(</span>
				<span className="text-[rgb(121,192,255)]">2</span>
				<span className="text-[rgb(230,237,243)]">)</span>
			</span>

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(230,237,243)]">{padSpaces(19)}</span>
				<span className="text-[rgb(255,123,114)]">-&gt;</span>
				<span className="text-[rgb(210,168,255)]">engagement</span>
				<span className="text-[rgb(230,237,243)]">(</span>
				<span className="text-[rgb(121,192,255)]">CarbonInterval</span>
				<span className="text-[rgb(255,123,114)]">::</span>
				<span className="text-[rgb(210,168,255)]">months</span>
				<span className="text-[rgb(230,237,243)]">(</span>
				<span className="text-[rgb(121,192,255)]">6</span>
				<span className="text-[rgb(230,237,243)]">))</span>
			</span>

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(230,237,243)]">{padSpaces(19)}</span>
				<span className="text-[rgb(255,123,114)]">-&gt;</span>
				<span className="text-[rgb(210,168,255)]">commencing</span>
				<span className="text-[rgb(230,237,243)]">(</span>
				<span className="text-[rgb(121,192,255)]">Carbon</span>
				<span className="text-[rgb(255,123,114)]">::</span>
				<span className="text-[rgb(210,168,255)]">parse</span>
				<span className="text-[rgb(230,237,243)]">(</span>
				<span className="text-[rgb(165,214,255)]">&apos;first Monday of next month&apos;</span>
				<span className="text-[rgb(230,237,243)]">))</span>
			</span>

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(230,237,243)]">{padSpaces(19)}</span>
				<span className="text-[rgb(255,123,114)]">-&gt;</span>
				<span className="text-[rgb(210,168,255)]">billingCycle</span>
				<span className="text-[rgb(230,237,243)]">(</span>
				<span className="text-[rgb(121,192,255)]">BillingCycles</span>
				<span className="text-[rgb(255,123,114)]">::</span>
				<span className="text-[rgb(121,192,255)]">QUARTERLY</span>
				<span className="text-[rgb(230,237,243)]">)</span>
			</span>

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(230,237,243)]">{padSpaces(19)}</span>
				<span className="text-[rgb(255,123,114)]">-&gt;</span>
				<span className="text-[rgb(210,168,255)]">hire</span>
				<span className="text-[rgb(230,237,243)]">();</span>
			</span>

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(230,237,243)]">{padSpaces(4)}</span>
				<span className="text-[rgb(230,237,243)]">{`}`}</span>
			</span>

			<span className="relative block w-full whitespace-pre">
				<span className="text-[rgb(230,237,243)]">{`}`}</span>
			</span>
		</div>);
}