import {Container}  from '@/domains/marketing/components/Container';
import {Disclosure} from '@headlessui/react';
import {
	MinusSmallIcon,
	PlusSmallIcon,
}                   from '@heroicons/react/24/outline';

const faqs = [
	{
		question: 'What technologies do you specialise in?',
		answer:   'We specialise in building robust and scalable applications using Laravel, Vue, and React Native. Laravel offers a powerful and elegant backend framework for web applications. Vue.js is our go-to for building interactive and dynamic user interfaces. For mobile applications, we leverage React Native to deliver native-like experiences on both iOS and Android platforms.',
	},
	{
		question: 'What flexible roles can your team members perform?',
		answer:   'We can develop Laravel applications, provide architectural advice, perform code review, and mentor your team. All of these services are interchangeable within the scope of your plan, so there\'s no need to renegotiate pricing if you need us to perform another role for you.',
	},
	{
		question: 'How do you tailor solutions to specific business needs?',
		answer:   'We begin with a detailed consultation to understand your unique business needs and challenges. Our solutions are then custom-designed to align with your business goals, integrating the most suitable technologies and strategies for your specific requirements.',
	},
	{
		question: 'Can you handle both web and mobile app development?',
		answer:   'Absolutely. Our expertise in Laravel and Vue.js makes us adept at developing cutting-edge web applications, while our proficiency in React Native enables us to create high-quality mobile applications. This combination ensures a seamless and integrated experience for users across platforms.',
	},
	{
		question: 'How do you ensure the quality of your software products?',
		answer:   'Quality is paramount at Atlas Software. We implement a thorough development process that includes rigorous testing, code reviews, and adherence to best practices in software development. We also engage in continuous integration and deployment practices, ensuring that our software products are reliable, efficient, and up-to-date.',
	},
	{
		question: 'What is the typical timeline for a project with Atlas Software?',
		answer:   'Project timelines vary based on complexity and scope. However, we are committed to efficient and timely delivery without compromising on quality. Typically, a mid-sized project takes about 3-6 months from conception to launch. We provide a detailed timeline after the initial consultation and requirements analysis.',
	},
	{
		question: 'How do you approach project management and communication?',
		answer:   'Effective communication is key to our project management approach. We assign a dedicated project manager to each project to ensure smooth communication and timely updates. We use modern project management tools and methodologies to keep the project on track and stakeholders informed at every stage.',
	},
	{
		question: 'Do you provide ongoing support and maintenance post-development?',
		answer:   'Yes, we offer comprehensive support and maintenance services post-development to ensure your application remains up-to-date and performs optimally. This includes regular updates, bug fixes, and feature enhancements as per your requirements.',
	},
];

export default function FAQ() {
	return (<div className="relative bg-teal-600">
		{/*<GradientTransition from="from-teal-600" />*/}

		<Container className="py-12">
			<div className="isolate mx-auto w-full divide-y divide-white/10">
				<h2 className="text-2xl font-bold leading-10 tracking-tight text-white">
					Frequently asked questions
				</h2>

				<dl className="mt-10 space-y-6 divide-y divide-white/10">
					{faqs.map((faq) => (<Disclosure as="div"
					                                key={faq.question}
					                                className="pt-6"
					>
						{({open}) => (<>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (<MinusSmallIcon className="h-6 w-6"
                                                   aria-hidden="true"
                          />) : (<PlusSmallIcon className="h-6 w-6"
                                                aria-hidden="true"
                          />)}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd"
                                      className="mt-2 pr-12"
                    >
                      <p className="text-base leading-7 text-teal-50/90 max-w-6xl font-medium">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>)}
					</Disclosure>))}
				</dl>
			</div>
		</Container>
	</div>);
}
