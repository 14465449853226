import {
	frequencies,
	monthlyRate,
	tiers,
}                  from '@/domains/shared/pricing';
import {CheckIcon} from '@heroicons/react/20/solid';
import classNames  from 'classnames';

export default function PricingGrid({
	                                frequency,
                                }) {
	return (
		<div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-4 mb-8">
			{tiers.map((tier) => (<div
				key={tier.id}
				className={classNames(tier.mostPopular ? 'bg-white/5 ring-2 ring-teal-500' : 'ring-1 ring-teal-50/50', 'rounded-3xl p-8 xl:p-10')}
			>
				<div className="flex items-center justify-between gap-x-4">
					<h3 id={tier.id}
					    className="text-lg font-semibold leading-8 text-white"
					>
						{tier.name}
					</h3>
					{tier.mostPopular ? (
						<p className="rounded-full bg-teal-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
							Most popular
						</p>) : null}
				</div>
				<p className="mt-4 text-sm leading-6 text-teal-100">{tier.description}</p>
				<div className="mt-6 space-y-4">
					{frequency > 0 && <p className="flex items-baseline gap-x-1 justify-center">
						<s className="text-2xl font-bold tracking-tight text-white">
							${Math.floor(tier.days * monthlyRate * frequencies[frequency].months)
							      .toLocaleString()}
						</s>
						<span className="text-sm font-semibold leading-6 text-teal-100">
							{frequencies[frequency].priceSuffix}
						</span>
					</p>}

					<p className="flex items-baseline gap-x-1 justify-center">
						<span className="text-4xl font-bold tracking-tight text-white -skew-x-[5deg]">
							${Math.floor(tier.days * monthlyRate * frequencies[frequency].discount * frequencies[frequency].months)
							      .toLocaleString()}
						</span>
						<span className="text-sm font-semibold leading-6 text-teal-100">
							{frequencies[frequency].priceSuffix}

							{/*(${(monthlyRate * frequencies[frequency].discount / 4 / 8)*/}
							{/*.toFixed(2)*/}
							{/*.toLocaleString()} / hour)*/}
						</span>
					</p>
				</div>
				<a href={tier.href}
				   aria-describedby={tier.id}
				   className={classNames(tier.mostPopular ? 'bg-teal-500 text-white shadow-sm hover:bg-teal-400 focus-visible:outline-teal-500' : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white', 'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2')}
				>
					Hire us
				</a>

				<ul role="list"
				    className="mt-8 space-y-3 text-sm leading-6 text-teal-100 xl:mt-10"
				>
					{tier.features.map((feature) => (<li key={feature}
					                                     className="flex gap-x-3"
					>
						<CheckIcon className="h-6 w-5 flex-none text-white"
						           aria-hidden="true"
						/>
						{feature}
					</li>))}
				</ul>
			</div>))}
		</div>);
}
