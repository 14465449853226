import classNames from 'classnames';

export default function GradientTransition({
	                                           direction = 'down',
	                                           from = '',
	                                           via = '',
	                                           to = '',
	                                           height = 'h-96',
                                           }) {
	return (<div className={classNames([
		'absolute inset-x-0',
		direction === 'down' ? 'top-0 bg-gradient-to-b' : 'bottom-0 bg-gradient-to-t',
		height,
		from,
		via,
		to,
	])}
	></div>);
}
