// const rate        = 187.5;
export const dayRate     = 1250;
export const monthlyRate = dayRate * 4;

export const frequencies = [
	{
		value:       'monthly',
		label:       'Month-to-month',
		priceSuffix: '/ month',
		discount:    ((100 - 0) / 100),
		months:      1,
	},
	{
		value:       'quarterly',
		label:       'Quarterly (10% off)',
		priceSuffix: '/ quarter',
		discount:    ((100 - 10) / 100),
		months:      3,
	},
	// {
	// 	value:       'annually',
	// 	label:       'Annually (20% off)',
	// 	priceSuffix: '/ year',
	// 	discount:    ((100 - 20) / 100),
	// 	months:      12,
	// },
];

export const tiers = [
	{
		name:        'Small',
		id:          'tier-half',
		href:        '/contact-us',
		days:        0.5,
		description: 'Businesses that need a small amount of regular maintenance.',
		features:    [
			'One team member for half a day per week',
			'One progress call per month',
			'Flexible role so you can get the most from your engagement',
		],
		mostPopular: false,
	},
	{
		name:        'Medium',
		id:          'tier-one',
		href:        '/contact-us',
		days:        1,
		description: 'Businesses that have regular feature requests.',
		features:    [
			'One team member for one day per week',
			'One progress call per fortnight',
			'Flexible role so you can get the most from your engagement',
		],
		mostPopular: true,
	},
	{
		name:        'Large',
		id:          'tier-two',
		href:        '/contact-us',
		days:        2,
		description: 'Large businesses that need a lot of support.',
		features:    [
			'One team member for two days per week',
			'One progress call per week',
			'Flexible role so you can get the most from your engagement',
		],
		mostPopular: false,
	},
	{
		name:        'Extra Large',
		id:          'tier-three',
		href:        '/contact-us',
		days:        3,
		description: 'Larger projects that are being actively developed.',
		features:    [
			'One team member for three days per week',
			'One progress call per week',
			'Flexible role so you can get the most from your engagement',
		],
		mostPopular: false,
	},
];

export const sections = [
	{
		name:     'Work',
		features: [
			{
				name:  'Days per week',
				tiers: {
					'Small':       'Half a day',
					'Medium':      '1',
					'Large':       '2',
					'Extra Large': '3',
				},
			},
			{
				name:  'Progress calls',
				tiers: {
					'Small':       'Monthly',
					'Medium':      'Fortnightly',
					'Large':       'Weekly',
					'Extra Large': 'Weekly',
				},
			},
			{
				name:  'Project management system',
				tiers: {
					'Small':       true,
					'Medium':      true,
					'Large':       true,
					'Extra Large': true,
				},
			},
		],
	},
	{
		name:     'Role',
		features: [
			{
				name:  'Senior developer',
				tiers: {
					'Small':       true,
					'Medium':      true,
					'Large':       true,
					'Extra Large': true,
				},
			},
			{
				name:  'Software architect',
				tiers: {
					'Small':       true,
					'Medium':      true,
					'Large':       true,
					'Extra Large': true,
				},
			},
			{
				name:  'Code reviewer',
				tiers: {
					'Small':       true,
					'Medium':      true,
					'Large':       true,
					'Extra Large': true,
				},
			},
			{
				name:  'Mentor',
				tiers: {
					'Small':       true,
					'Medium':      true,
					'Large':       true,
					'Extra Large': true,
				},
			},
		],
	},
	{
		name:     'Support',
		features: [
			{
				name:  'Email support',
				tiers: {
					'Small':       true,
					'Medium':      true,
					'Large':       true,
					'Extra Large': true,
				},
			},
			{
				name:  'Phone support',
				tiers: {
					'Small':       true,
					'Medium':      true,
					'Large':       true,
					'Extra Large': true,
				},
			},
			{
				name:  'Zoom support',
				tiers: {
					'Small':       true,
					'Medium':      true,
					'Large':       true,
					'Extra Large': true,
				},
			},
			{
				name:  'Emergency support (out of hours)',
				tiers: {
					'Small':       false,
					'Medium':      false,
					'Large':       false,
					'Extra Large': false,
				},
			},
		],
	},
];