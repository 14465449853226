import {Container} from '@/domains/marketing/components/Container';
import {
	frequencies,
	monthlyRate,
	sections,
	tiers,
}                  from '@/domains/shared/pricing';
import {
	CheckIcon,
	MinusIcon,
}                  from '@heroicons/react/20/solid';
import classNames  from 'classnames';
import {Fragment}  from 'react';

export default function PricingTable({
	                                     frequency,
                                     }) {
	return (<div className="relative py-8">
		<Container>
			{/* xs to lg */}
			<div className="mx-auto max-w-md space-y-8 lg:hidden">
				{tiers.map((tier) => (<section
					key={tier.id}
					className={classNames(tier.mostPopular ? 'rounded-xl bg-white/5 ring-1 ring-inset ring-white/10' : '', 'p-8')}
				>
					<h3 id={tier.id}
					    className="text-sm font-semibold leading-6 text-white"
					>
						{tier.name}
					</h3>
					<p className="mt-2 flex items-baseline gap-x-1">
						<span className="text-4xl font-bold text-white -skew-x-[5deg]">${Math.floor(tier.days * monthlyRate * frequencies[frequency].discount * frequencies[frequency].months)
						                                                                     .toLocaleString()}</span>
						<span className="text-sm font-semibold text-gray-300">{frequencies[frequency].priceSuffix}</span>
					</p>
					<a
						href={tier.href}
						aria-describedby={tier.id}
						className={classNames(tier.mostPopular ? 'bg-teal-500 text-white hover:bg-teal-400 focus-visible:outline-teal-500' : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white', 'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2')}
					>
						Hire us
					</a>
					<ul role="list"
					    className="mt-10 space-y-4 text-sm leading-6 text-white"
					>
						{sections.map((section) => (<li key={section.name}>
							<ul role="list"
							    className="space-y-4"
							>
								{section.features.map((feature) => feature.tiers[tier.name] ? (<li key={feature.name}
								                                                                   className="flex gap-x-3"
								>
									<CheckIcon className="h-6 w-5 flex-none text-teal-400"
									           aria-hidden="true"
									/>
									<span>
										{feature.name}{' '}
										{typeof feature.tiers[tier.name] === 'string' ? (
											<span className="text-sm leading-6 text-teal-400">({feature.tiers[tier.name]})</span>) : null}
									</span>
								</li>) : null)}
							</ul>
						</li>))}
					</ul>
				</section>))}
			</div>

			{/* lg+ */}
			<div className="isolate hidden lg:block">
				<div className="relative -mx-8">
					{tiers.some((tier) => tier.mostPopular) ? (<div className="absolute inset-x-4 inset-y-0 -z-10 flex">
						<div
							className="flex w-1/5 px-4"
							aria-hidden="true"
							style={{marginLeft: `${(tiers.findIndex((tier) => tier.mostPopular) + 1) * 20}%`}}
						>
							<div className="w-full rounded-t-xl border-x border-t border-white/10 bg-white/5" />
						</div>
					</div>) : null}
					<table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
						<caption className="sr-only">Pricing plan comparison</caption>
						<colgroup>
							<col className="w-1/5" />
							<col className="w-1/5" />
							<col className="w-1/5" />
							<col className="w-1/5" />
							<col className="w-1/5" />
						</colgroup>
						<thead>
						<tr>
							<td />
							{tiers.map((tier) => (<th key={tier.id}
							                          scope="col"
							                          className="px-6 pt-6 xl:px-8 xl:pt-8"
							>
								<div className="text-lg font-semibold leading-7 text-white">{tier.name}</div>
							</th>))}
						</tr>
						</thead>
						<tbody>
						<tr>
							<th scope="row">
								<span className="sr-only">Price</span>
							</th>
							{tiers.map((tier) => (<td key={tier.id}
							                          className="px-6 pt-2 xl:px-8"
							>
								<div className="space-y-2">
									{frequency > 0 && <p className="flex items-baseline gap-x-1 justify-enter">
										<s className="text-lg font-bold tracking-tight text-white">
											${Math.floor(tier.days * monthlyRate * frequencies[frequency].months)
											      .toLocaleString()}
										</s>
										<span className="text-sm font-semibold leading-6 text-white">
											{frequencies[frequency].priceSuffix}
										</span>
									</p>}

									<p className="flex items-baseline gap-x-1 justify-enter">
										<span className="text-2xl font-bold tracking-tight text-white -skew-x-[5deg]">
											${Math.floor(tier.days * monthlyRate * frequencies[frequency].discount * frequencies[frequency].months)
											      .toLocaleString()}
										</span>
										<span className="text-sm font-semibold leading-6 text-white">
											{frequencies[frequency].priceSuffix}

											{/*(${(monthlyRate * frequencies[frequency].discount / 4 / 8)*/}
											{/*.toFixed(2)*/}
											{/*.toLocaleString()} / hour)*/}
										</span>
									</p>
								</div>

								<a href={tier.href}
								   className={classNames(tier.mostPopular ? 'bg-teal-500 hover:bg-teal-400 focus-visible:outline-teal-600' : 'bg-white/10 hover:bg-white/20 focus-visible:outline-white', 'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2')}
								>
									Hire us
								</a>
							</td>))}
						</tr>
						{sections.map((section, sectionIdx) => (<Fragment key={section.name}>
							<tr>
								<th
									scope="colgroup"
									colSpan={4}
									className={classNames(sectionIdx === 0 ? 'pt-8' : 'pt-16', 'pb-4 text-sm font-semibold leading-6 text-white')}
								>
									{section.name}
									<div className="absolute inset-x-8 mt-4 h-px bg-white/10" />
								</th>
							</tr>
							{section.features.map((feature) => (<tr key={feature.name}>
								<th scope="row"
								    className="py-4 text-sm font-normal leading-6 text-white"
								>
									{feature.name}
									<div className="absolute inset-x-8 mt-4 h-px bg-white/5" />
								</th>
								{tiers.map((tier) => (<td key={tier.id}
								                          className="px-6 py-4 xl:px-8"
								>
									{typeof feature.tiers[tier.name] === 'string' ? (
										<div className="text-center text-sm leading-6 text-teal-300">
											{feature.tiers[tier.name]}
										</div>) : (<>
                                {feature.tiers[tier.name] === true ? (
	                                <CheckIcon className="mx-auto h-5 w-5 text-teal-400"
	                                           aria-hidden="true"
	                                />) : (<MinusIcon className="mx-auto h-5 w-5 text-teal-50"
                                                      aria-hidden="true"
                                />)}

										<span className="sr-only">
                                  {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                                </span>
                              </>)}
								</td>))}
							</tr>))}
						</Fragment>))}
						</tbody>
					</table>
				</div>
			</div>
		</Container>
	</div>);
}
